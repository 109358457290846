:root {
  --dark-color: #000;
  --light-color-0:#fff;
  --light-color-1: #ddd;
  --primary-color:#e00000;
  --secondary-color:#b33636;
  
  /*FieldDiv variables */
  --div-field-margin:0px auto;
  --div-field-padding:0px 5px 10px;

  /*Label variables */
  --label-margin:0px 0px 5px 0px;
  --label-color: var(--dark-color);
  --label-weight:100;
  --label-font-size:13px;
  --label-line-height:15px;

  /*Input variables */
  --input-width: -webkit-fill-available;
  --input-height: 32px;
  --input-margin:0px auto 8px;
  --input-padding:2px 5px;
  --input-background-color: var(--light-color-0);
  --input-border-width: 1px;
  --input-border-style: solid;
  --input-border-color: var(--light-color-1);
  --input-border-radius: 8px;

  /*Focus*/
  --input-focus-color: --dark-color;
  --input-focus-background: var(--light-color-1);
  --input-focus-border-color: var(--secondary-color);

  /*FieldDiv with errors variables */
  --input-error-background-color: var(--light-color-0);
  --input-error-border-width: 1px;
  --input-error-border-style: solid;
  --input-error-border-color: var(--primary-color);

  /*ValidationDiv message variables */
  --message-font-size:12px;
  --message-line-height:13px;
  --message-margin:0px 0px 5px 0px;
  --message-color:var(--primary-color);
  --message-weight:bold;
  
  /*Submit button variables */
  --form-submit-cursor: pointer;
  --form-submit-width: 50%;
  --form-submit-background: var(--primary-color);
  --form-submit-color: #fff;
  --form-submit-font-size: 12px;
  --form-submit-height: auto;
  --form-submit-font-weight: 400;
  --form-submit-padding: 14px 0;
  --form-submit-margin: 0;
  --form-submit-text-transform: uppercase;
  --form-submit-border: 1px solid var(--secondary-color);
  --form-submit-border-radius: 8px;

  --form-submit-hover-color: #fff;
  --form-submit-hover-background: var(--secondary-color);
  --form-submit-hover-border: 1px solid var(--secondary-color);

  --form-submit-focus-color: #fff;
  --form-submit-focus-background:  var(--secondary-color);
  --form-submit-focus-border: 1px solid var(--secondary-color);
  --form-submit-focus-outline: none;

}

html{
  font-family: Arial, Helvetica, sans-serif;
}

/* FieldDiv */
form[class^="cds-"] > div[class^="div-"]{
  margin: var(--div-field-margin);
  padding: var(--div-field-padding);
}

/* Labels */
form[class^="cds-"] label{
  margin: var(--label-margin);
  color: var(--label-color);
  font-weight: var(--label-weight);
  font-size: var(--label-font-size);
  line-height: var(--label-line-height);
}

/* Inputs */
form[class^="cds-"] input,select{
  width: var(--input-width);
  height: var(--input-height);
  margin: var(--input-margin);
  padding:  var(--input-padding);
  background: var(--input-background-color);
  border-width: var(--input-border-width);
  border-style: var(--input-border-style);
  border-color: var(--input-border-color);
  border-radius: var(--input-border-radius);
}
/* Dates */
/* form[class^="cds-"] .form-field-dateselect {
  width: 30%;
} */
form[class^="cds-"] select[position="month"]{
  width: calc(100% - 175px);
}
form[class^="cds-"] select[position="days"]{
  width: 75px;
}
form[class^="cds-"] select[position="years"]{
  width: 100px;
}

form[class^="cds-"] input:focus{
  color: var(--input-focus-color);
  background: var(--input-focus-background);
  border-color: var(--input-border-color);
  outline: none;
}

/*FieldDiv with errors - input*/
form[class^="cds-"] > div[class="cds-input-error"] > input[class^="form-field"]{
  background: var(--input-error-background-color);
  border-width: var(--input-error-border-width);
  border-style: var(--input-error-border-style);
  border-color: var(--input-error-border-color);
}

/* ValidationDiv message */
form[class^="cds-"] div[class="validation-error"] p{
  margin: var(--message-margin);
  color: var(--message-color);
  font-weight: var(--message-weight);
  font-size: var(--message-font-size);
  line-height: var(--message-line-height);
}

/* Submit button */
form[class^="cds-"] button[class^="cds-form-submit"] {
  cursor: var(--form-submit-cursor);
  width: var(--form-submit-width);
  background-color: var(--form-submit-background);
  color: var(--form-submit-color);
  font-size: var(--form-submit-font-size);
  height: var(--form-submit-height);
  font-weight: var(--form-submit-font-weight);
  padding: var(--form-submit-padding);
  margin: var(--form-submit-margin);
  text-transform: var(--form-submit-text-transform);
  border: var(--form-submit-border);
  border-radius: var(--form-submit-border-radius);
}
form[class^="cds-"] button[class^="cds-form-submit"]:focus {
  color: var(--form-submit-focus-color);
  border: var(--form-submit-focus-border);
  background-color:var(--form-submit-focus-background);
}
form[class^="cds-"] button[class^="cds-form-submit"]:hover {
  color: var(--form-submit-hover-color);
  background-color: var(--form-submit-hover-background);
  border: var(--form-submit-border);
} 

/* Center submit button example */
div[class^="cds-form-submit"] {
  display: flex;
  justify-content: center;
  padding-bottom: 25px;
}

/* Custom input types*/
/* Check box */
form[class^="cds-"] input[type='checkbox'] {}
form[class^="cds-"] input[type='checkbox'] + label {}

/* Radio */
form[class^="cds-"] input[type='radio'] {}
form[class^="cds-"] input[type='radio'] span {}

/* Custom show password button*/
[id*="Password"] > input[type=checkbox] {}
[id*="Password"] > input[type=checkbox] + label:before {} /* general styles*/
[id*="Password"] > input[type=checkbox] + label:before {} /* unchecked */
[id*="Password"] > input[type=checkbox]:checked + label:before {} /* checked */
