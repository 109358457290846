
/* For pdf */
.pdf__container__document {
  margin: 1em 0;
}
.pdf__container__document .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pdf__container__document .react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 1em;
}
.pdf__container__document .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}
.pdf__container__document .react-pdf__message {
  padding: 20px;
  color: white;
}

.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}

div.paginator {
  text-align: center;
}
