.MuiBadge-badge {
    height: 20px;
    display: flex;
    padding: 0 6px;
    z-index: 1;
    position: absolute;
    flex-wrap: wrap;
    font-size: 0.75rem;
    min-width: 20px;
    box-sizing: border-box;
    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    font-family: "TCCC-UnityText", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1;
    align-content: center;
    border-radius: 10px;
    flex-direction: row;
    justify-content: center;
    background-color: white;
    color:#000000;
    font-size: 10px;
}



.react-horizontal-scrolling-menu--scroll-container {
    display: flex;
    height: max-content;
    overflow-y: hidden;
    position: relative;
    margin-top: 10px;
}
