@font-face {
  font-family: 'TCCC-UnityText';
  src: local('TCCC-UnityText');
  src: url('./assets/fonts/Text/TCCC-UnityText-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Text/TCCC-UnityText-Regular.woff') format('woff'); /* Pretty Modern Browsers */
}
@font-face {
  font-family: 'TCCC-UnityText';
  src: local('TCCC-UnityText');
  src: url('./assets/fonts/Text/TCCC-UnityText-Black.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Text/TCCC-UnityText-Black.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: black;
}
@font-face {
  font-family: 'TCCC-UnityText';
  src: local('TCCC-UnityText');
  src: url('./assets/fonts/Text/TCCC-UnityText-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Text/TCCC-UnityText-Bold.woff') format('woff');
  font-weight: bold; /* Pretty Modern Browsers */
}
@font-face {
  font-family: 'TCCC-UnityText';
  src: local('TCCC-UnityText');
  src: url('./assets/fonts/Text/TCCC-UnityText-BoldItalic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Text/TCCC-UnityText-BoldItalic.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'TCCC-UnityText';
  src: local('TCCC-UnityText');
  src: url('./assets/fonts/Text/TCCC-UnityText-Italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Text/TCCC-UnityText-Italic.woff') format('woff'); /* Pretty Modern Browsers */
  font-style: italic;
}
@font-face {
  font-family: 'TCCC-UnityText';
  src: local('TCCC-UnityText');
  src: url('./assets/fonts/Text/TCCC-UnityText-Light.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Text/TCCC-UnityText-Light.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: light;
}
@font-face {
  font-family: 'TCCC-UnityText';
  src: local('TCCC-UnityText');
  src: url('./assets/fonts/Text/TCCC-UnityText-Medium.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Text/TCCC-UnityText-Medium.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: medium;
}

@font-face {
  font-family: 'TCCC-UnityHeadline';
  src: local('TCCC-UnityHeadline');
  src: url('./assets/fonts/Headline/TCCC-UnityHeadline-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Headline/TCCC-UnityHeadline-Regular.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: 400;
}
@font-face {
  font-family: 'TCCC-UnityHeadline';
  src: local('TCCC-UnityHeadline');
  src: url('./assets/fonts/Headline/TCCC-UnityHeadline-Black.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Headline/TCCC-UnityHeadline-Black.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: 800;
}
@font-face {
  font-family: 'TCCC-UnityHeadline';
  src: local('TCCC-UnityHeadline');
  src: url('./assets/fonts/Headline/TCCC-UnityHeadline-Light.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Headline/TCCC-UnityHeadline-Light.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: 300;
}
@font-face {
  font-family: 'TCCC-UnityHeadline';
  src: local('TCCC-UnityHeadline');
  src: url('./assets/fonts/Headline/TCCC-UnityHeadline-Medium.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Headline/TCCC-UnityHeadline-Medium.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: 500;
}
@font-face {
  font-family: 'TCCC-UnityHeadline';
  src: local('TCCC-UnityHeadline');
  src: url('./assets/fonts/Headline/TCCC-UnityHeadline-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Headline/TCCC-UnityHeadline-Bold.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: 700;
}

@font-face {
  font-family: 'TCCC-UnityCondensed';
  src: local('TCCC-UnityCondensed');
  src: url('./assets/fonts/Condensed/TCCC-UnityCondensed-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Condensed/TCCC-UnityCondensed-Bold.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: bold;
}
@font-face {
  font-family: 'TCCC-UnityCondensed';
  src: local('TCCC-UnityCondensed');
  src: url('./assets/fonts/Condensed/TCCC-UnityCondensed-Medium.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Condensed/TCCC-UnityCondensed-Medium.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: medium;
}

body {
  background-color: #eaeaea;
}

html {
  background-color: #eaeaea;
}