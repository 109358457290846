#loader {
    position: absolute;
    margin: auto;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    width: 50%;
    height: 50%;
    max-width: 150px;
    max-height: 150px;
    padding-top: 48px;
}
@keyframes loader {
  0% { left: -100px }
  100% { left: 110%; }
}
#cart {
  width: 150px;
  height: 150px;
  animation: animateC linear infinite;
  animation-duration: 2s;
  position: absolute;
  top: 0;
  left: 0;
}
#box {
  width: 50px;
  height: 50px;
  background: #fafafa;
  animation: animate .5s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
}
#product {
  animation: animateC linear infinite;
  animation-duration: 1.5s;
  position: absolute;
  top: 100px;
  left: 100px;
  right: 0px;
  margin: auto;
  z-index: -1;
}
#product1 {
  animation: animateP1 linear infinite;
  animation-duration:6s;
  
}
#product2 {
  animation: animateP2 linear infinite;
  animation-duration: 6s;
}
@keyframes animate {
  17% { border-bottom-right-radius: 3px; }
  25% { transform: translateY(9px) rotate(22.5deg); }
  50% {
    transform: translateY(18px) scale(1,.9) rotate(45deg) ;
    border-bottom-right-radius: 40px;
  }
  75% { transform: translateY(9px) rotate(67.5deg); }
  100% { transform: translateY(0) rotate(90deg); }
}

@keyframes animateC {
  25% { transform: translateY(0px) rotate(22.5deg);  }
  50% {
    transform: translateY(25px) rotate(45deg);
  }
  75% { transform: translateY(50px) rotate(67.5deg);}
  100% { transform: translateY(75px) rotate(90deg); }
}

@keyframes animateP1 {
  25% { transform: translateY(25px)  }
  50% {
    transform: translateY(0px);
  }
  75% { transform: translateY(0px);}
  100% { transform: translateY(0px) }
}

@keyframes animateP2 {
  25% { transform: translateY(25px)  }
  50% {
    transform: translateY(25px);
  }
  75% { transform: translateY(0px);}
  100% { transform: translateY(0px) }
}
#shadow {
  width: 50px;
  height: 5px;
  background: #000;
  opacity: 0.1;
  position: absolute;
  top: 59px;
  left: 0;
  border-radius: 50%;
  animation: shadow .5s linear infinite;
}
@keyframes shadow {
  50% {
    transform: scale(1.2,1);
  }
}


#africa {
  animation: animateAfrica linear infinite;
  animation-duration: 6s;
  
}
#america {
  animation: animateAmerica linear infinite;
  animation-duration: 3s;
}
@keyframes animateAfrica {
  25% { opacity: 0; }
  50% {
    opacity: 0; 
  }
  75% {  opacity: 1; }
  100% {  opacity: 1; }
}
@keyframes animateAmerica {
  25% { opacity: 0; }
  50% {
    opacity: 0; 
  }
  75% {  opacity: 1; }
  100% {  opacity: 1; }
}